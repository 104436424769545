



nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    padding-top: 10px;
    width: 75%;
    top: 0;
    left: 0;
    height: 70px;
 }


 .logo-nav img {
    height: 95px;
 }


 nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 3rem;
    flex: 0 0 auto;
 }


 .home-images nav a {
    color: black;
 }


 nav a {
    color: #02122D;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
 }


 nav a.active {
    font-weight: bold;
    color: rgb(2, 66, 155);
 }




    nav ul li a.normal {
        color: white;
        text-decoration: none;
        font-weight: normal; /* Use 'normal' for non-bold text */
        font-size: 20px;
    }


    nav ul li a.active {
        font-weight: bold;
        /* Add any other styles you want for clicked links */
    }






 .mobile-menu-icon{
    display: none;
 }


 @media (max-width: 900px) {
    .logo-nav {
        z-index: 1;
    }
    .nav-links {
        display: none;
    }
    .nav-links-mobile {
        position: absolute;
        flex-direction: column;
        list-style: none;
        box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        left: 0;
        top: 15%;
        transition: all 0.5s ease-in-out;
        background-color: #0A2C5E;
        justify-content: center;
        align-items: center;
      }
      nav ul {
        padding: 10px 0px;
        width: 100%;
        transition: all 0.5s ease-in-out;
        text-align: center;
      }
      .mobile-menu-icon {
        position: absolute;
        display: block;
        right: 20px;
        background-color: white;
        border: none;
      }


      .nav-links-mobile a {
        width: 40%;
        color: white;
      }


      div a {
        margin-left: 0;
        margin-right: 0;
        font-size: 20px;
        color: white;
      }


      nav ul li a.active {
        font-weight: bold;
       }
        svg {
        font-size: large;
    }


 }


 @media (min-width: 901px) and (max-width: 1024px){
    nav {
        width: 90%;
    }
 }
