

.Footer {
    padding: 2rem 0;
    background: whitesmoke;
    margin-top: 5rem;
   }
   
   
   .row {
    display: flex;
    margin: 0px 60px;
    justify-content: space-between;
   }
   
   
   .footer-logo {
    width: 150px;
   }
   
   
   .ft-1 h3 {
    font-weight: 700;
    letter-spacing: 2px;
    padding: 0.5rem 0;
   }
   
   
   .ft-1 span {
    color: rgb(2, 66, 155);
   }
   
   
   .ft-1 p {
    padding: 0rem 5rem 0.8rem 0;
    font-weight: 500;
   }
   
   
   .footer-icons i {
    padding: 0.4rem 0.5rem;
    background: #e1e1e1;
    color: rgb(2, 66, 155);
    margin: 0 0.5rem;
    border-radius: 50%;
   }
   
   
   .footer-icons i:hover {
    background: rgb(2, 66, 155);
    color: #fff;
    transition: 0.6s;
    cursor: pointer;
   }
   
   
   .Footer h5 {
    color:  rgb(2, 66, 155);
    margin-right: 5px;
   }
   
   
   .ft-2 ul {
    list-style: none;
    padding-left: 0;
   }
   
   
   .ft-2 ul li {
    padding: 0.35rem 0;
    font-weight: 500;
   }
   
   
   .ft-2 ul a {
    text-decoration: none;
    color: #000;
    font-size: 1.06rem;
   }
   
   
   .ft-2 ul a:hover {
    color: rgb(2, 66, 155);
   }
   
   
   .ft-3 p {
    font-weight: 500;
    padding: 0.1rem 0;
    font-size: 1.06rem;
   }
   
   
   .ft-3 i {
    padding-right: 0.5rem;
   }
   
   
   .Last-footer {
    background:  rgb(2, 66, 155);
    text-align: center;
    padding: 1rem 0;
   }
   
   
   .Last-footer p {
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 700;
    color: white;
   }
   
   
   i {
      color: rgb(2, 66, 155);
   }
   
   
   
   
   @media (max-width: 600px) {
    .Footer {
      margin-top: 10rem;
    }
    .row {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
   
   
   }
   
   
   @media (min-width: 601px) and (max-width: 992px) {
    .Footer {
      margin-top: 10rem;
    }
    .row {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
   }
   